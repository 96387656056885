@font-face {
  font-family: "Entypo";
  src: url("~react-native-vector-icons/Fonts/Entypo.ttf") format("truetype");
}

@font-face {
  font-family: "EvilIcons";
  src: url("~react-native-vector-icons/Fonts/EvilIcons.ttf") format("truetype");
}

@font-face {
  font-family: "FontAwesome";
  src: url("~react-native-vector-icons/Fonts/FontAwesome.ttf")
    format("truetype");
}

@font-face {
  font-family: "fontcustom";
  src: url("~react-native-vector-icons/Fonts/Foundation.ttf") format("truetype");
}

@font-face {
  font-family: "Ionicons";
  src: url("~react-native-vector-icons/Fonts/Ionicons.ttf") format("truetype");
}

@font-face {
  font-family: 'MaterialCommunityIcons';
  src: url("~react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf")
    format("truetype");
}

@font-face {
  font-family: "MaterialIcons";
  src: url("~react-native-vector-icons/Fonts/MaterialIcons.ttf")
    format("truetype");
}

@font-face {
  font-family: "Octicons";
  src: url("~react-native-vector-icons/Fonts/Octicons.ttf") format("truetype");
}

@font-face {
  font-family: "simple-line-icons";
  src: url("~react-native-vector-icons/Fonts/SimpleLineIcons.ttf")
    format("truetype");
}

@font-face {
  font-family: "Zocial";
  src: url("~react-native-vector-icons/Fonts/Zocial.ttf") format("truetype");
}
